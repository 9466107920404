body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to bottom, #FF5B7F, #FC9272);
  width: 100vw;
  height: 100vh;
}

#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"), url("/public/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"), url("/public/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"), url("/public/fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"), url("/public/fonts/Poppins-Bold.ttf");
}

.MuiSwitch-thumb {
  background-color: #AAAAAA !important;
}
.Mui-checked .MuiSwitch-thumb {
  background-color: rgb(253, 126, 119) !important;
}
